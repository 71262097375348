import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userType: 'employee',
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userType: (state, action) => {
      state.userType = action?.payload ?? null;
    },
  },
});

export const { userType } = user.actions;
export default user.reducer;