import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCommissionType:'total',
}

export const commission = createSlice({
  name: 'commission',
  initialState,
  reducers: {
    isCommissionType: (state, action) => {
      state.isCommissionType = action?.payload ?? 'total';
    },
  },
});

export const { isCommissionType } = commission.actions;
export default commission.reducer;


