import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationType:'created',
}

export const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationType: (state, action) => {
      state.notificationType = action?.payload ?? 'created';
    },
  },
});

export const { notificationType } = notification.actions;
export default notification.reducer;


