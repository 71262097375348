import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isContractView:'view',
}

export const contract = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    isContractView: (state, action) => {
      state.isContractView = action?.payload ?? 'view';
    },
  },
});

export const { isContractView } = contract.actions;
export default contract.reducer;


