import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action?.payload ?? null;
    },
    logout: () => initialState,
  },
});

export const { login, logout } = auth.actions;
export default auth.reducer;