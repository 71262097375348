import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
  const auth = useSelector((state) => state?.auth?.user);
  if (!auth?.authToken) return children;

  return <Navigate to="/dashboard" />;
};

export default PublicRoute;