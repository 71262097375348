import axios from "axios";
import { API_AXIOS } from "../_interceptors/interceptor";


export const _isValidEsignLink = async (payload) => {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_URL+"/contract/esign/"+payload.code);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const _submitEsignature = async (payload) => {
  try {
    const response = await API_AXIOS.post("/contract/esign/"+payload.code, { signature: payload.signature});
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}