import React, { useEffect, useState } from "react";

function DesktopWarning() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile) {
    return (
      <div style={fullScreenWarningStyle}>
        <h1 style={textStyle}>For a better experience, please use a tab or desktop device.</h1>
      </div>
    );
  }

  return null;
}

const fullScreenWarningStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "#ffcc00",
  color: "#333",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
};

const textStyle = {
  fontSize: "24px",
  fontWeight: "bold",
  textAlign: "center",
  padding: "20px",
};

export default DesktopWarning;
