import { Button, Spinner } from '@chakra-ui/react'
import React from 'react'

const CustomButton = ({children, handler=()=>{}, loading=false, type="primary", style={}}) => {
    const theme = {
        primary: {
            bg: '#069FFF',
            color: '#FFFFFF',
            hover:'#0a95ed',
        },
        secondary: {
            bg: '#A4A4A4',
            color: '#FFFFFF',
            hover:'#898787',
        },
        danger:{
            bg: '#C10404',
            color: '#FFFFFF',
            hover:'#a90303',
        },
    }
    
    return (
        <Button  onClick={handler} style={style} color={theme[type].color} boxShadow='md' fontSize={15} fontWeight={600} borderRadius={11} _hover={{ bg: theme[type].hover }} textAlign={'center'} bg={theme[type].bg}>
            {loading ? <><Spinner size={"md"} mr={"10px"} /> Please Wait ... </>: children}
        </Button>
    )
}

export default CustomButton