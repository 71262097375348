import { Box, Input, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import SignaturePad from 'signature_pad'
import CustomButton from '../components/CustomButton'
import { _submitEsignature } from './esignApi'
import { toast } from 'react-toastify'


const SignPad = ({code, updateSignData}) => {
  const canvasRef = useRef(null)
  const signaturePad = useRef(null)
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)

  useEffect(() => {
    if(canvasRef.current){
        window.onresize = resizeCanvas;
        resizeCanvas();
        signaturePad.current = new SignaturePad(canvasRef.current, {
          backgroundColor: 'rgba(255, 255, 255, 0)',
          penColor: 'black'
        })
    }
    return () => {
        window.onresize = null;
    }
  }, [])

  const resizeCanvas = () => {
    canvasRef.current.width = Math.min(window.innerWidth - 80, 400)
    canvasRef.current.height = Math.min(window.innerWidth - 80, 400) / 2;
  }


  const handleSignatureChange = (e) => {
    const image = new Image()
    image.src = URL.createObjectURL(e.target.files[0])
    image.onload = () => {
        signaturePad.current.fromDataURL(image.src)
    }
  }
  

  const clearSignature = () => {
    signaturePad.current.clear()
    inputRef.current.value = ''
  }

  const saveSignature = async () => {
    if(signaturePad.current.isEmpty()) {
      toast.error("Please draw your signature")
      return;
    }
    const dataURL = signaturePad.current.toDataURL()
    await submit(dataURL)
  }


  const submit = async (signature) => {
    setLoading(true)
    try{
      const res = await _submitEsignature({code, signature})
      updateSignData({
        isSuccess: true,
        file: res.data.file,
        message: res.message,
      })
    } catch(e) {
      setError(e?.response?.data?.message ?? "Something went wrong")
      toast.error(e?.response?.data?.message ?? "Something went wrong")
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
        <canvas ref={canvasRef} style={{border: '1px solid black', padding: '2px'}} width={400} height={200}></canvas>
        <Text marginTop={"10px"}>or, upload your signature image:</Text>
        <Input ref={inputRef} type={"file"} accept={"image/*"} maxWidth={400} padding={'4px'} marginTop={"10px"} onChange={handleSignatureChange} />
        <Box marginTop={"10px"} marginBottom={'80px'} maxW={400} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <CustomButton handler={clearSignature} type='secondary'>Clear</CustomButton>
          <CustomButton handler={saveSignature} type='primary' loading={loading}>Save Signature</CustomButton>
        </Box>
    </>
  )
}

export default SignPad