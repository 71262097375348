import { Box, Grid, Icon, Image, Spinner, Text } from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom"
import { _isValidEsignLink } from "./esignApi"
import { getFields } from "./fields"
import { useEffect, useState } from "react"
import errorAnimation from './error.json'
import successAnimation from './success.json'
import { motion } from "framer-motion"
import Lottie from "lottie-react"
import SignPad from "./SignPad"
import CustomLink from "../components/CustomLink"
import { MdKeyboardArrowLeft } from "react-icons/md"

const Esign = () => {
  const {code} = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(undefined)
  const [signData, setSignData] = useState({
    isSuccess: false,
    file: undefined,
    message: "Document Signed Successfully",
  })

  const updateSignData = (data) =>{
    setSignData(data)
  }

  useEffect(() => {
    checkValid()
  }, [])

  const checkValid = async () => {
    try{
      const {data=undefined} = await _isValidEsignLink({code})
      console.log(data)
      if(data){
        setData(data)
      }
    } catch(e) {
      console.log(e.response);
      
      setError(e?.response?.data?.message ?? "Something went wrong")
    } finally {
      setLoading(false)
    }
  }


  const wrapper = (element) => {
    return <Box width={'100%'} minH={['200px', '520px']} display={'grid'} placeItems={'center'} padding={'20px'}>
      {element}
    </Box>
  }
 
  if (loading) {
   return wrapper(
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
      <Spinner size={"xl"} marginBottom={"20px"} scale={2}/>
      <Text fontWeight={600} fontSize={20}>Loading...</Text>
    </Box>)
  }

  if (error) {
    return wrapper(
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <Lottie 
          animationData={errorAnimation}
          loop={false}
          autoplay={true}
          style={{height: 300, width: 300}}
        />
        <motion.div initial={{opacity: 0}} transition={{ ease: "easeOut", duration: 1, delay: 1.2 }} animate={{opacity: 1}} style={{fontWeight: 600, fontSize: 20, position: "relative", top: "-60px"}}>{error}</motion.div>
      </Box>
    )
  }

  if (signData.isSuccess) {
    return wrapper(
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <Lottie 
          animationData={successAnimation}
          loop={false}
          autoplay={true}
          style={{height: 300, width: 300}}
        />
        <motion.div initial={{opacity: 0}} transition={{ ease: "easeOut", duration: 1, delay: 1.2 }} animate={{opacity: 1}} style={{position: "relative", top: "-60px", display:"flex", alignItems:"center", justifyContent:"center", flexDirection: "column"}}>
          <Text style={{fontWeight: 600, fontSize: 20}} marginBottom={"3px"}>{signData.message}</Text>
          <CustomLink type="primary" to={`${process.env.REACT_APP_BASE_URL}/file/${signData.file}?download=true`} newTab={true} style={{marginTop: "10px"}}>Download Document</CustomLink>
          <CustomLink type="basic" to={"/"} style={{display:"inline-flex", alignItems:"center", marginTop: "10px"}}>
            <Icon as={MdKeyboardArrowLeft} fontSize={"25px"}/>
            Back to Home
            </CustomLink>
        </motion.div>
      </Box>
    )
  }


  return (
    <Box width={'100%'} height={'auto'} display={'grid'} placeItems={'center'} padding={'20px'}>
      <Box height={'auto'} width={[300, 600, 900]}>
        <Box display={'flex'} alignItems={'center'} gap={2} marginBottom={'20px'} paddingY={"20px"} borderBottom={"2px solid black"}>
          <Image src={'/logo.svg'} />
          <Text fontWeight={600}>Crystal Utilities</Text>
        </Box>
        <Box>
          {getFields(data).map((field, index) => {
            return (
              <Box key={index} marginTop={"30px"}>
                <Text fontWeight={600} fontSize={20}>{field.title}</Text>
                <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={'25px'} marginY={"20px"}>
                  {field.data.map((data, indx) => {
                    return (
                      <Box key={indx}>
                        <Text fontWeight={500} marginBottom={'10px'}>{data.name}</Text>
                        <Text borderBottom={"1px solid #e0e0e0"} paddingBottom={'5px'} color={"#606160"}>{data.value}</Text>
                      </Box>
                    )
                  })}
                </Grid>
              </Box>
            )
          })}
        </Box>
        <Box marginTop={"50px"} marginBottom={"20px"}>
          <Text fontWeight={600} fontSize={20}>Sign Here</Text>
        </Box>
        <SignPad code={code} updateSignData={updateSignData} />
      </Box>
    </Box>
  )
}

export default Esign