import axios from 'axios';
import { store } from '../_redux/store';
import { toast } from 'react-toastify';

const API_AXIOS = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

API_AXIOS.interceptors.request.use(
  (config) => {
    const token = store.getState()?.auth?.user?.authToken || null;
console.log({token});

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['ngrok-skip-browser-warning'] = true;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



API_AXIOS.interceptors.response.use(
  (response) => {
    return response;
  },


  async (error) => {
    let message = 'Something went wrong';
    if (error.response) {
      if (error?.response.config.responseType === 'blob') {
        try {
          const text = await error?.response.data.text();
          const errorData = JSON.parse(text);
          console.log('Error Data:', errorData);
          message = errorData?.message || 'Internal Server Error';
        } catch (error) {
          console.error('Error parsing response:', error);
        }
      }else{
        message = error.response.data?.message || 'Internal Server Error';
      }
    } else if (error?.request) {
      message = error?.response?.data?.message || 'Network error. Please try again later.';
    }
    toast.error(message);
    return Promise.reject(error);
  }
)
export { API_AXIOS }