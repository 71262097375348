
export const getFields = (data) => {

  const dateFormatter = (date) => {
    if(!date) return;
    const options = { year: 'numeric', month: 'short', day: 'numeric'};
    return new Date(date).toLocaleDateString('en-US', options);
  }
  
  return [
    {
      title: "Suppler Details",
      data: [
        {
          name: "Supplier",
          value: data?.supplier?.name ?? "N/A"
        },
        {
          name: "Utility Type",
          value: data?.utility
        }
      ]
    },
    {
      title: "Gas Details",
      data: [
        {
          name: "Gas Meter Serial Number",
          value: data?.gasDetails?.gmsNumber
        },
        {
          name: "Gas Meter Reading",
          value: data?.gasDetails?.gmr
        }
      ]
    },
    {
      title: "Contract Details",
      data: [
        {
          name: "Contract ID",
          value: data?.contractId
        },
        {
          name: "Agent",
          value: `${data?.agent?.name} (${data?.agent?.agentId})`
        },
        {
          name: "MPR",
          value: data?.contractDetails?.mpr
        },
        {
          name: "Gas Consumption (kWh)",
          value: data?.contractDetails?.gasConsumption
        },
        {
          name: "Estimated Start Date (from supplier)",
          value: dateFormatter(data?.contractDetails?.estimatedStartDate)
        },
        {
          name: "Contract End Date",
          value: dateFormatter(data?.contractDetails?.contractEndDate)
        },
        {
          name: "Billing Period",
          value: data?.contractDetails?.billingPeriod
        },
        {
          name: "Requested Date",
          value: dateFormatter(data?.contractDetails?.requestedDate)
        },
        {
          name: "Payment",
          value: data?.contractDetails?.payment
        },
        {
          name: "Payment Type",
          value: data?.contractDetails?.paymentType
        },
        {
          name: "Contract Cost",
          value: data?.gasDetails?.sellingPrice?.contractCost
        },
        {
          name: "Annual Cost",
          value: data?.gasDetails?.basePrice?.annualCost
        }
      ]
    }, 
    {
      title: "Customer Details",
      data: [
        {
          name: "Title",
          value: data?.customerDetails?.title
        },
        {
          name: "Customer Name",
          value: data?.customerDetails?.firstName + " " + data?.customerDetails?.surName
        },
        {
          name: "Email address",
          value: data?.customerDetails?.email
        },
        {
          name: "Phone number",
          value: data?.customerDetails?.phone
        },
      ] 
    },
    {
      title: "Site Details",
      data: [
        {
          name: "Postcode",
          value: data?.siteDetails?.postCode
        },
        {
          name: "Address Name",
          value: data?.siteDetails?.addressName
        },
        {
          name: "Address Number",
          value: data?.siteDetails?.addressNumber
        },
        {
          name: "Street 01",
          value: data?.siteDetails?.street1
        },
        {
          name: "Street 02",
          value: data?.siteDetails?.street2
        },
        {
          name: "Town",
          value: data?.siteDetails?.town
        },
        {
          name: "Country",
          value: data?.siteDetails?.country
        }
      ]
    }, 
    {
      title: "Bank Details",
      data: [
        {
          name: "Account Name",
          value: data?.bankDetails?.holderName
        }, 
        {
          name: "Bank Name",
          value: data?.bankDetails?.bankName
        }, 
        {
          name: "Account Number",
          value: data?.bankDetails?.accountNumber
        }, 
        {
          name: "Payment Date",
          value: dateFormatter(data?.bankDetails?.paymentDate)
        }, 
        {
          name: "Sortcode",
          value: data?.bankDetails?.sortCode
        }
      ]
    }
  ]
}